import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Language {
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  public languages: Language[] = [
    {
      name: 'IT',
      code: 'it'
    },
    {
      name: 'DE',
      code: 'de'
    },
    {
      name: 'EN',
      code: 'en'
    }
  ];

  constructor(private translateService: TranslateService) { }

  getUserCategories() {
    return [
      { code: 'wine', name: this.translateService.instant('category.wine') },
      { code: 'beer', name: this.translateService.instant('category.beer') },
      { code: 'spirit', name: this.translateService.instant('category.spirit') },
      { code: 'food', name: this.translateService.instant('category.food') },
      { code: 'importer', name: this.translateService.instant('category.importer') }
      // {code: 'all', name: this.translateService.instant("category.all")}
    ];
  }

  getAgricultureTypes() {
    return [
      {
        code: 'conventional',
        name: this.translateService.instant('agriculture.conventional')
      },
      /* {
        code: 'biologicalConventional',
        name: this.translateService.instant("agriculture.biologicalConventional")
      },
      {
        code: 'biodynamicsConventional',
        name: this.translateService.instant("agriculture.biodynamicsConventional")
      }, */
      {
        code: 'biological',
        name: this.translateService.instant('agriculture.biological')
      },
      /* {
        code: 'biodynamicsBiological',
        name: this.translateService.instant("agriculture.biodynamicsBiological")
      }, */
      {
        code: 'biodynamics',
        name: this.translateService.instant('agriculture.biodynamics')
      },
      /* {
        code: 'conversion',
        name: this.translateService.instant("agriculture.conversion")
      }, */
      {
        code: 'natural',
        name: this.translateService.instant('agriculture.natural')
      },
      {
        code: 'piwi',
        name: this.translateService.instant('agriculture.piwi')
      },
      {
        code: 'orange',
        name: this.translateService.instant('agriculture.orange')
      },
      /*{
          code: 'other',
          name: this.translateService.instant("agriculture.other")
      }*/
      {
        code: 'sqnpi',
        name: this.translateService.instant('agriculture.sqnpi')
      }
    ];
  }

  getProductCategories() {
    return [
      { code: 'wine', name: this.translateService.instant('category.wine') },
      { code: 'beer', name: this.translateService.instant('category.beer') },
      { code: 'spirit', name: this.translateService.instant('category.spirit') },
      { code: 'food', name: this.translateService.instant('category.food') }
    ];
  }

  getFoodCategories() {
    return [
      // {code: 'meat', name: this.translateService.instant("category.food.meat")},
      { code: 'coffee', name: this.translateService.instant('category.food.coffee') },
      { code: 'meatsausages', name: this.translateService.instant('category.food.sausages') },
      { code: 'vinegar', name: this.translateService.instant('category.food.vinegar') },
      { code: 'dessert', name: this.translateService.instant('category.food.dessert') },
      { code: 'flour', name: this.translateService.instant('category.food.flour') },
      { code: 'mushroom', name: this.translateService.instant('category.food.mushroom') },
      { code: 'marmalade', name: this.translateService.instant('category.food.marmalade') },
      { code: 'honey', name: this.translateService.instant('category.food.honey') },
      { code: 'oil', name: this.translateService.instant('category.food.oil') },
      { code: 'pasta', name: this.translateService.instant('category.food.pasta') },
      { code: 'bakery', name: this.translateService.instant('category.food.bakery') },
      { code: 'fish', name: this.translateService.instant('category.food.fish') },
      { code: 'cheese', name: this.translateService.instant('category.food.cheese') },
      { code: 'vegetables', name: this.translateService.instant('category.food.vegetables') },
      { code: 'salt', name: this.translateService.instant('category.food.salt') },
      { code: 'sauce', name: this.translateService.instant('category.food.sauce') },
      // {code: 'undercooked', name: this.translateService.instant("category.food.undercooked")},
      // {code: 'specialFood', name: this.translateService.instant("category.food.specialFood")},
      // {code: 'regionalFood', name: this.translateService.instant("category.food.regionalFood")},
      { code: 'other', name: this.translateService.instant('category.food.other') }
    ];
  }

  getUserTypes() {
    return [
      { code: 'board', name: this.translateService.instant('user.type.board') },
      { code: 'admin', name: this.translateService.instant('user.type.admin') },
      { code: 'supplier', name: this.translateService.instant('user.type.supplier') }
      // {code: 'editor', name: this.translateService.instant("user.type.editor")}
    ];
  }

  getShortUserTypes() {
    return [
      { code: 'admin', name: this.translateService.instant('user.type.admin') },
      { code: 'supplier', name: this.translateService.instant('user.type.supplier') }
    ];
  }

  getProductStates() {
    return [
      { code: 'added', name: this.translateService.instant('product.state.added') },
      { code: 'toSend', name: this.translateService.instant('product.state.toSend') },
      { code: 'sent', name: this.translateService.instant('product.state.sent') },
      { code: 'received', name: this.translateService.instant('product.state.received') },
      { code: 'evaluated', name: this.translateService.instant('product.state.evaluated') }
    ];
  }

  getProductStatesShort() {
    return [
      { code: 'added', name: this.translateService.instant('product.state.added') },
      { code: 'toSend', name: this.translateService.instant('product.state.toSend') },
      { code: 'sent', name: this.translateService.instant('product.state.sent') }
    ];
  }

  getFermentationTypes() {
    return [
      { code: 'high', name: this.translateService.instant('product.fermentation.high') },
      {
        code: 'spontaneous',
        name: this.translateService.instant('product.fermentation.spontaneous')
      },
      { code: 'low', name: this.translateService.instant('product.fermentation.low') }
    ];
  }

  getProductProvenances() {
    return [
      { code: 'regional', name: this.translateService.instant('product.provenance.regional') },
      { code: 'national', name: this.translateService.instant('product.provenance.national') },
      {
        code: 'international',
        name: this.translateService.instant('product.provenance.international')
      }
    ];
  }

  getBeerDenominations() {
    return [
      { code: 'DOP', name: this.translateService.instant('product.beerDenomination.DOP') },
      { code: 'IGP', name: this.translateService.instant('product.beerDenomination.IGP') },
      { code: 'STG', name: this.translateService.instant('product.beerDenomination.STG') },
      { code: 'other', name: this.translateService.instant('product.beerDenomination.other') }
    ];
  }

  getWineDenominations() {
    return [
      { code: 'DOC', name: this.translateService.instant('product.wineDenomination.DOC') },
      { code: 'DOCG', name: this.translateService.instant('product.wineDenomination.DOCG') },
      { code: 'IGT', name: this.translateService.instant('product.wineDenomination.IGT') },
      { code: 'DOP', name: this.translateService.instant('product.wineDenomination.DOP') },
      { code: 'IGP', name: this.translateService.instant('product.wineDenomination.IGP') },
      { code: 'VSQ', name: this.translateService.instant('product.wineDenomination.VSQ') },
      //{code: 'VSQPRD', name: this.translateService.instant("product.wineDenomination.VSQPRD")},
      { code: 'VS', name: this.translateService.instant('product.wineDenomination.VS') },
      //{code: 'VLQPRD', name: this.translateService.instant("product.wineDenomination.VLQPRD")},
      { code: 'VSQA', name: this.translateService.instant('product.wineDenomination.VSQA') },
      { code: 'VDT', name: this.translateService.instant('product.wineDenomination.VDT') },
      { code: 'other', name: this.translateService.instant('product.wineDenomination.other') }
    ];
  }

  getCraftTypes() {
    return [
      { code: 'gt70', name: this.translateService.instant('product.craftType.gt70') },
      { code: 'gte50', name: this.translateService.instant('product.craftType.gte50') },
      { code: 'lt50', name: this.translateService.instant('product.craftType.lt50') }
    ];
  }

  getSpiritCategories() {
    return [
      {
        code: 'distillate',
        name: this.translateService.instant('product.spiritCategory.distillate')
      },
      { code: 'liquor', name: this.translateService.instant('product.spiritCategory.liquor') },
      { code: 'other', name: this.translateService.instant('product.spiritCategory.other') }
    ];
  }

  getSpiritTypes() {
    return [
      { code: 'wine', name: this.translateService.instant('product.spiritType.wine') },
      { code: 'cereal', name: this.translateService.instant('product.spiritType.cereal') },
      { code: 'fruit', name: this.translateService.instant('product.spiritType.fruit') },
      { code: 'plants', name: this.translateService.instant('product.spiritType.plants') },
      { code: 'other', name: this.translateService.instant('product.spiritType.other') }
    ];
  }

  getWineTypes() {
    return [
      { code: 'red', name: this.translateService.instant('product.wineType.red') },
      { code: 'sweetRed', name: this.translateService.instant('product.wineType.sweetRed') },
      {
        code: 'sparklingRed',
        name: this.translateService.instant('product.wineType.sparklingRed')
      },
      { code: 'white', name: this.translateService.instant('product.wineType.white') },
      { code: 'sweetWhite', name: this.translateService.instant('product.wineType.sweetWhite') },
      {
        code: 'sparklingWhite',
        name: this.translateService.instant('product.wineType.sparklingWhite')
      },
      { code: 'rose', name: this.translateService.instant('product.wineType.rose') },
      { code: 'sparkling', name: this.translateService.instant('product.wineType.sparkling') },
      {
        code: 'sweetSparkling',
        name: this.translateService.instant('product.wineType.sweetSparkling')
      },
      {
        code: 'sparklingRose',
        name: this.translateService.instant('product.wineType.sparklingRose')
      },
      { code: 'other', name: this.translateService.instant('product.wineType.other') }
    ];
  }

  getSparklingTypes() {
    return [
      { code: 'brutZero', name: this.translateService.instant('category.sparkling.brutZero') },
      { code: 'extraBrut', name: this.translateService.instant('category.sparkling.extraBrut') },
      { code: 'brut', name: this.translateService.instant('category.sparkling.brut') },
      { code: 'extraDry', name: this.translateService.instant('category.sparkling.extraDry') },
      { code: 'dry', name: this.translateService.instant('category.sparkling.dry') },
      { code: 'mediumDry', name: this.translateService.instant('category.sparkling.mediumDry') },
      { code: 'sweet', name: this.translateService.instant('category.sparkling.sweet') }
    ];
  }

  getAgingTypes() {
    return [
      { code: 'gte70Barrique', name: this.translateService.instant('product.aging.gte70Barrique') },
      { code: 'lt70Barrique', name: this.translateService.instant('product.aging.lt70Barrique') },
      { code: 'usedBarrique', name: this.translateService.instant('product.aging.usedBarrique') },
      { code: 'tonneaux', name: this.translateService.instant('product.aging.tonneaux') },
      { code: 'bigBarrels', name: this.translateService.instant('product.aging.bigBarrels') },
      { code: 'caratello', name: this.translateService.instant('product.aging.caratello') },
      { code: 'steel', name: this.translateService.instant('product.aging.steel') },
      { code: 'cement', name: this.translateService.instant('product.aging.cement') },
      { code: 'amphora', name: this.translateService.instant('product.aging.amphora') },
      { code: 'classic', name: this.translateService.instant('product.aging.classic') },
      { code: 'charma', name: this.translateService.instant('product.aging.charma') }
      // {code: 'other', name: this.translateService.instant("product.aging.other")}
    ];
  }

  getLongevityTypes() {
    return [
      { code: 'lte5', name: this.translateService.instant('product.longevity.lte5') },
      { code: 'lt10', name: this.translateService.instant('product.longevity.lt10') },
      { code: 'gte10', name: this.translateService.instant('product.longevity.gte10') }
    ];
  }

  getVineyardTypes() {
    return [
      { code: 'albarello', name: this.translateService.instant('product.vineyard.albarello') },
      { code: 'cordon', name: this.translateService.instant('product.vineyard.cordon') },
      { code: 'guyot', name: this.translateService.instant('product.vineyard.guyot') },
      { code: 'doubleGuyot', name: this.translateService.instant('product.vineyard.doubleGuyot') },
      { code: 'pergola', name: this.translateService.instant('product.vineyard.pergola') },
      { code: 'tent', name: this.translateService.instant('product.vineyard.tent') },
      { code: 'other', name: this.translateService.instant('product.vineyard.other') }
    ];
  }

  getIrrigationTypes() {
    return [
      { code: 'drop', name: this.translateService.instant('product.irrigation.drop') },
      { code: 'spraying', name: this.translateService.instant('product.irrigation.spraying') },
      { code: 'none', name: this.translateService.instant('product.irrigation.none') },
      { code: 'other', name: this.translateService.instant('product.irrigation.other') }
    ];
  }

  getEventStates() {
    return [
      { code: 'created', name: this.translateService.instant('event.state.created') },
      { code: 'started', name: this.translateService.instant('event.state.started') },
      { code: 'ended', name: this.translateService.instant('event.state.ended') },
      { code: 'canceled', name: this.translateService.instant('event.state.canceled') }
    ];
  }

  getEventManualStates() {
    return [
      { code: 'created', name: this.translateService.instant('event.state.created') },
      // {code: 'started', name: this.translateService.instant("event.state.started")},
      // {code: 'ended', name: this.translateService.instant("event.state.ended")},
      { code: 'canceled', name: this.translateService.instant('event.state.canceled') }
    ];
  }

  getYesNoValues() {
    return [
      { code: true, name: this.translateService.instant('general.yes') },
      { code: false, name: this.translateService.instant('general.no') }
    ];
  }

  getNoYesValues() {
    return [
      { code: false, name: this.translateService.instant('general.yes') },
      { code: true, name: this.translateService.instant('general.no') }
    ];
  }

  getEventRegistrationStates() {
    return [
      { code: 'created', name: this.translateService.instant('eventRegistration.state.created') },
      {
        code: 'waiting_payment',
        name: this.translateService.instant('eventRegistration.state.waiting_payment')
      },
      {
        code: 'request_transfer_payment',
        name: this.translateService.instant('eventRegistration.state.request_transfer_payment')
      },
      {
        code: 'confirmed',
        name: this.translateService.instant('eventRegistration.state.confirmed')
      },
      { code: 'canceled', name: this.translateService.instant('eventRegistration.state.canceled') }
    ];
  }

  getProvenance() {
    return [
      { code: 'regional', name: this.translateService.instant('product.provenance.regional') },
      { code: 'national', name: this.translateService.instant('product.provenance.national') },
      {
        code: 'international',
        name: this.translateService.instant('product.provenance.international')
      }
    ];
  }
}
