<div class="Card">
  <div class="Card__title" >
    <div *ngIf="paymentGateway === 'stripe'; else altTitle">
      {{ 'process.title' | translate  }}
    </div>
  </div>
  <ng-template #altTitle>
    La richiesta di pagamento è stata accolta
  </ng-template>
  <p>{{ subject }}</p>
  <p *ngFor="let item of items"><small >{{ item }}</small></p>
  <p>Totale ordine: {{totalAmount.toFixed(2)}}</p>
  <hr>
  <div *ngIf="showPaymentInfo">
    <h5>Hai fatto richiesta di pagamento tramite bonifico.</h5>
    <p>Di seguito le coordinate per procedere al pagamento:</p>
    <p>Intestatario: <b>GOURMET’S INTERNATIONAL SRL</b></p>
    <p>Banca: <b>DEUTSCHE BANK ITALIA</b></p>
    <p>IBAN: <b>IT36F0310401620000000871776</b></p>
    <p>BIC: <b>DEUTITM1474</b></p>
    <p>CAUSALE: <b>{{ subject }}</b></p>
    <p>IMPORTO: <b>€ {{totalAmount.toFixed(2)}}</b></p>
    <p></p>
    <p><u> Il pagamento sarà verificato dall'amministrazione in base ai tempi dell'operazione bancaria e riceverai una mail di conferma.</u></p>
  </div>
  <div class="uk-grid">
    <div class="uk-width-1-1">
      <button class="m-button m-button--inline" (click)="goBack()">
        {{ 'button.undo' | translate }}
      </button>
    </div>
  </div>
</div>
