import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchCacheService } from '../../core/search-cache.service';
import { ModalService } from '../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../core/authentication.service';
import { LoginUser } from './LoginUser';
import { UserService } from '../../core/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  passwordType: 'password' | 'text' = 'password';
  user: LoginUser = {} as LoginUser;
  userInfo: any;
  rememberMe: boolean;
  @ViewChild('formLogin') formLogin;
  private sub: Subscription = new Subscription();

  constructor(
    private searchCacheService: SearchCacheService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.searchCacheService.reset();
    if (this.authService.getToken()) {
      if (this.route.snapshot.queryParamMap.get('redirect')) {
        this.router.navigate([this.route.snapshot.queryParamMap.get('redirect')]);
      }
    } else {
      this.authService.logout();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    // this.authService.previousUrl = null;
  }

  login() {
    this.formLogin.submitted = this.formLogin.$invalid;
    if (this.formLogin.invalid) {
      this.modalService.error(this.translateService.instant('general.form.error'));
      return;
    }

    this.spinnerService.show();
    this.authService.login(this.user.username, this.user.password).subscribe(
      (r: any) => {
        if (r.token) {
          this.authService.setToken(r.token);
          this.authService.setAllowedRouting().then(
            ok => {
              if (this.route.snapshot.queryParamMap.get('redirect')) {
                this.router.navigate([this.route.snapshot.queryParamMap.get('redirect')]);
              } else {
                localStorage.setItem('firstSessionLogin', '1');
                if (r.user.userType === 'board') {
                  this.router.navigate(['/dashboard/evaluation']);
                } else {
                  this.router.navigate(['/dashboard/summary']);
                }
              }
            },
            err => { }
          );
        } else {
          this.modalService.error(this.translateService.instant('general.error'));
        }
        this.spinnerService.hide();
      },
      res => {
        this.spinnerService.hide();
        // Account non ancora confermato.
        if (res.error && res.error.err === 'notVerified') {
          this.modalService.errorHtml(this.translateService.instant('login.notVerified'), 60000);
          return;
        }
        this.modalService.errorHtml(this.translateService.instant('login.error'));
      }
    );
  }

  showPassword() {
    if (this.passwordType === 'text') {
      this.passwordType = 'password';
    } else {
      this.passwordType = 'text';
    }
  }
}
