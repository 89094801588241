import { Pipe, PipeTransform } from '@angular/core';
import { FileService } from 'core/file.service';

@Pipe({
  name: 'attachment',
  pure: true
})
export class AttachmentPipe implements PipeTransform {
  constructor(private fileService: FileService) {}

  transform(value: string, ...args: any[]): string {
    const relativePath = args[0] ?? '/v2/attachments/';
    return this.fileService.getUrl(value, relativePath);
  }
}
