import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProductModalComponent } from './add-product-modal.component';
import { Step1Component } from './step1/step1.component';
import { UserAutocompleteComponent } from './step1/user-autocomplete/user-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormProgressModule } from '../form-progress/form-progress.module';
import { TranslateModule } from '@ngx-translate/core';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProductMarketsComponent } from './product-markets/product-markets.component';
import { Step5Component } from './step5/step5.component';
import { Step6Component } from './step6/step6.component';
import { Step7Component } from './step7/step7.component';
import { UploadImageBoxModule } from '../upload-image-box/upload-image-box.module';
import { DirectivesModule } from '../directives/directives.module';
import { RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';
import { Step8Component } from './step8/step8.component';
import { SharedModule } from 'shared/shared.module';
import { MaterialModule } from 'shared/material/material.module';

@NgModule({
  declarations: [
    AddProductModalComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component,
    UserAutocompleteComponent,
    ProductMarketsComponent,
    Step8Component
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FormProgressModule,
    TranslateModule,
    BsDatepickerModule,
    UploadImageBoxModule,
    DirectivesModule,
    TooltipModule,
    NgxSelectModule,
    MaterialModule
  ],
  exports: [AddProductModalComponent]
})
export class AddProductModalModule {}
