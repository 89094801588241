import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PaginatedResponse } from 'models';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  private baseURL = `${environment.serverV2}/shop`;
  private settingsBaseURL = `${environment.serverV2}/settings/shop`;

  constructor(private http: HttpClient) { }

  getOrder(orderID: string) {
    return this.http.get<any>(`${this.baseURL}/order/${orderID}`).pipe(take(1));
  }

  createPayment(order): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/order`, order).pipe(take(1));
  }

  modifyOrder(order): Observable<any> {
    return this.http.put<any>(`${this.baseURL}/order`, order).pipe(take(1));
  }

  cancelOrder(orderID: string): Observable<any> {
    return this.http.put<any>(`${this.baseURL}/order/${orderID}/cancel`, {}).pipe(take(1));
  }

  getOrders(page: number, limit: number, body: any): Observable<PaginatedResponse<any>> {
    return this.http
      .post<PaginatedResponse<any>>(`${this.baseURL}/orders?page=${page}&limit=${limit}`, body)
      .pipe(take(1));
  }

  getProducts(category: string) {
    return this.http.get<any[]>(`${this.settingsBaseURL}/products/${category}`).pipe(take(1));
  }

  modifyProduct(product) {
    return this.http.put(`${this.settingsBaseURL}/product`, product).pipe(take(1));
  }

  removeProduct(id: string) {
    return this.http.delete(`${this.settingsBaseURL}/product/${id}`).pipe(take(1));
  }

  exportOrders(body: any) {
    return this.http
      .post(`${this.baseURL}/orders/export`, body, {
        responseType: 'arraybuffer'
      })
      .pipe(
        take(1),
        tap(res => {
          let binary = '';
          const bytes = new Uint8Array(res);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset:UTF-8;base64,${window.btoa(
            binary
          )}`;

          const downloadLink = document.createElement('a');
          const fileName = `Orders.xlsx`;

          downloadLink.href = url;
          downloadLink.download = fileName;
          downloadLink.click();
        })
      );
  }
}
