<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <app-user-autocomplete
    [userType]="userType"
    [productDetailMode]="productDetailMode"
    [product]="product"
    (userChange)="changeUser($event)"
  ></app-user-autocomplete>

  <div class="uk-grid">
    <div class="uk-width-1-1" *ngIf="!product.category || userType == 'admin'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.category' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="m-select" [ngClass]="{ 'ng-invalid': form.submitted && !product.category }">
            <select
              [(ngModel)]="product.category"
              (change)="switchProductCategory()"
              name="category"
              [disabled]="productDetailMode || readonly"
              required
            >
              <option value=""></option>
              <option *ngFor="let cat of productCategories" [value]="cat.code">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1" *ngIf="product.category == 'wine'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'stato' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <div class="m-select" [ngClass]="{ 'ng-invalid': form.submitted && !product.country }">
            <select
              name="country"
              [(ngModel)]="product.country"
              (change)="onSelectCountry()"
              required
              [disabled]="readonly"
            >
              <option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category == 'wine'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label"
            >{{ 'regione' | translate }} <span *ngIf="districts.length > 0">*</span></label
          >
        </div>
        <div [ngClass]="column">
          <div
            class="m-select"
            *ngIf="districts.length > 0"
            [ngClass]="{ 'ng-invalid': form.submitted && !product.region }"
          >
            <select
              name="district"
              [(ngModel)]="product.region"
              (change)="onSelectDistrict()"
              required
              [disabled]="readonly"
            >
              <option value=""></option>
              <option *ngFor="let district of districts" [value]="district.name">
                {{ district.name }}
              </option>
            </select>
          </div>
          <!--Per paesi non mappati sul database con le proprie regioni-->
          <input
            type="text"
            class="m-input"
            name="district"
            [readonly]="readonly"
            [(ngModel)]="product.region"
            *ngIf="districts.length == 0"
          />
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category == 'wine'">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label">{{ 'product.microzone' | translate }} *</label>
        </div>
        <div [ngClass]="column">
          <ngx-select
            [items]="microzones"
            [(ngModel)]="product.wineTechnical.microzone"
            optionTextField="name"
            name="microzone"
            [required]="!missingMicrozone.checked"
            [disabled]="readonly || missingMicrozone.checked"
            noResultsFound="Nessun risultato trovato"
          ></ngx-select>
        </div>
        <div [ngClass]="column">
          <input
            #missingMicrozone
            type="checkbox"
            name="missingMicrozone"
            [checked]="product.wineTechnical.manualMicrozone"
            (change)="setMicrozone()"
            [disabled]="readonly"
          />
          {{ 'product.microzone_not_present' | translate }}
        </div>
        <div [ngClass]="column" *ngIf="missingMicrozone.checked">
          <input
            type="text"
            class="m-input"
            [(ngModel)]="product.wineTechnical.manualMicrozone"
            name="manualMicrozone"
            [readonly]="readonly"
            [placeholder]="'product.microzone' | translate"
            [required]="missingMicrozone.checked"
          />
        </div>
      </div>
    </div>

    <div class="uk-width-1-1">
      <div class="uk-grid">
        <div [ngClass]="column">
          <label class="m-label"
            >{{ 'product.name' | translate }} <span *ngIf="product.category !== 'wine'">*</span>
            <span
              *ngIf="product.category === 'wine'"
              tooltip="{{ 'product.tooltip.wineName' | translate }}"
              >(?)</span
            ></label
          >
        </div>
        <div [ngClass]="column">
          <input
            type="text"
            class="m-input"
            [(ngModel)]="product.name"
            name="name"
            [readonly]="readonly"
            [required]="product.category !== 'wine'"
          />
        </div>
      </div>
    </div>

    <div class="uk-width-1-1" *ngIf="product.category == 'wine'">
      <div class="uk-grid">
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.type' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.wineTechnical?.wineType }"
              >
                <select
                  [(ngModel)]="product.wineTechnical.wineType"
                  name="wineType"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of wineTypes" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-width-1-1"
          *ngIf="
            product.wineTechnical?.wineType == 'sparkling' ||
            product.wineTechnical?.wineType == 'sparklingRose'
          "
        >
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.sparkling.type' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{
                  'ng-invalid': form.submitted && !product.wineTechnical?.sparklingType
                }"
              >
                <select
                  [(ngModel)]="product.wineTechnical.sparklingType"
                  name="sparklinkType"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of sparklingTypes" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.denomination' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.wineTechnical?.denomination }"
              >
                <select
                  [(ngModel)]="product.wineTechnical.denomination"
                  name="denomination"
                  required
                  [disabled]="readonly"
                  (change)="onSelectDenomination()"
                >
                  <option *ngFor="let cat of wineDenominations" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label"
                >{{ 'product.area.denomination' | translate }} *
                <span tooltip="{{ 'product.tooltip.wineDenomination' | translate }}"
                  >(?)</span
                ></label
              >
            </div>
            <div [ngClass]="column">
              <ngx-select
                [items]="denominations"
                [(ngModel)]="product.wineTechnical.areaDenomination"
                optionTextField="name"
                name="areaDenomination"
                required
                [disabled]="readonly || missingAreaDenomination.checked"
              ></ngx-select>
            </div>
            <div [ngClass]="column">
              <input
                #missingAreaDenomination
                type="checkbox"
                name="missingAreaDenomination"
                [checked]="product.wineTechnical?.manualAreaDenomination"
                (change)="setDenomination()"
                [disabled]="readonly"
              />
              {{ 'product.wine.denomination_not_present' | translate }}
            </div>
            <div [ngClass]="column" *ngIf="missingAreaDenomination.checked">
              <input
                type="text"
                class="m-input"
                [(ngModel)]="product.wineTechnical.manualAreaDenomination"
                name="manualAreaDenomination"
                [readonly]="readonly"
                [placeholder]="'product.area.denomination' | translate"
                [required]="missingAreaDenomination.checked"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.specification' | translate }}</label>
            </div>
            <div [ngClass]="column">
              <div class="m-select">
                <select
                  [(ngModel)]="product.wineTechnical.specification"
                  name="specification"
                  [disabled]="readonly"
                >
                  <option [value]=""></option>
                  <option *ngFor="let spec of specifications" [value]="spec.name">
                    {{ spec.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.other.specification' | translate }}</label>
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                class="m-input"
                [(ngModel)]="product.wineTechnical.otherSpecification"
                name="otherSpecification"
                [readonly]="readonly"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1" *ngFor="let year of product.wineTechnical.years; index as i">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label"
                >{{ 'product.year' | translate }} *
                <span *ngIf="i !== 0" [hidden]="readonly">
                  <i class="m-action--delete icon ti-trash ko" (click)="removeYear(i)"></i>
                </span>
              </label>
            </div>
            <div [ngClass]="column">
              <ngx-select
                [items]="years"
                [(ngModel)]="year.year"
                [name]="'year-' + i"
                [disabled]="readonly"
                [ngClass]="{ 'ng-invalid': form.submitted && year.year === '' }"
                noResultsFound="Nessun risultato trovato"
                required
              ></ngx-select>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-text-right" [hidden]="readonly">
          <i class="m-action icon fa fa-plus-circle" (click)="addYear()"></i>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1" *ngIf="product.category == 'spirit'">
      <div class="uk-grid">
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.alcohol.perc' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                inputDecimal
                [readonly]="readonly"
                (decimalChanged)="product.spiritTechnical.degree = $event"
                class="m-input"
                name="degree"
                [(ngModel)]="product.spiritTechnical.degree"
                required
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.spirit.denomination' | translate }}</label>
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                class="m-input"
                [(ngModel)]="product.spiritTechnical.denomination"
                [readonly]="readonly"
                name="denomination"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.type' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{
                  'ng-invalid': form.submitted && !product.spiritTechnical?.spiritCategory
                }"
              >
                <select
                  [(ngModel)]="product.spiritTechnical.spiritCategory"
                  name="spiritCategory"
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of spiritCategories" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1" *ngIf="product.spiritTechnical.spiritCategory === 'distillate'">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.spirit.type' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.spiritTechnical?.spiritType }"
              >
                <select
                  [(ngModel)]="product.spiritTechnical.spiritType"
                  name="spiritType"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of spiritTypes" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1" *ngIf="product.spiritTechnical.spiritCategory === 'other'">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.spiritType.otherType' | translate }}</label>
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                class="m-input"
                [(ngModel)]="product.spiritTechnical.otherType"
                [readonly]="readonly"
                name="otherType"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.craftType' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.spiritTechnical.craft }"
              >
                <select
                  [(ngModel)]="product.spiritTechnical.craft"
                  name="craft"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of craftTypes" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category == 'food'">
      <div class="uk-grid">
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.type' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.foodTechnical?.categoryFood }"
              >
                <select
                  [(ngModel)]="product.foodTechnical.categoryFood"
                  name="categoryFood"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of foodCategories" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1" *ngIf="product.foodTechnical.categoryFood === 'other'">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label tooltipz">{{ 'Altri (specifica)' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                class="m-input"
                [(ngModel)]="product.categoryFoodOther"
                required
                [readonly]="readonly"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.denomination' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.foodTechnical?.denomination }"
              >
                <select
                  [(ngModel)]="product.foodTechnical.denomination"
                  name="denomination"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of foodDenominations" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.craftType' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <div
                class="m-select"
                [ngClass]="{ 'ng-invalid': form.submitted && !product.foodTechnical?.craft }"
              >
                <select
                  [(ngModel)]="product.foodTechnical.craft"
                  name="craft"
                  required
                  [disabled]="readonly"
                >
                  <option *ngFor="let cat of craftTypes" [value]="cat.code">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1" *ngIf="product.category == 'beer'">
      <div class="uk-grid">
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.beer.craft' | translate }}</label>
            </div>
            <div [ngClass]="column">
              <div class="uk-grid">
                <div class="uk-width-1-4">
                  <label>
                    <input
                      type="radio"
                      [(ngModel)]="product.beerTechnical.craft"
                      [value]="true"
                      name="craft"
                      [disabled]="readonly"
                      group="craft"
                    />
                    {{ 'general.yes' | translate }}
                  </label>
                </div>
                <div class="uk-width-1-4">
                  <label>
                    <input
                      type="radio"
                      [(ngModel)]="product.beerTechnical.craft"
                      [value]="false"
                      name="craft"
                      [disabled]="readonly"
                      group="craft"
                    />
                    {{ 'general.no' | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label" tooltip="{{ 'general.form.beerStyle' | translate }}"
                >(?) {{ 'product.style' | translate }} *</label
              >
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                [(ngModel)]="product.beerTechnical.type"
                class="m-input"
                name="type"
                [readonly]="readonly"
                required
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-grid">
            <div [ngClass]="column">
              <label class="m-label">{{ 'product.color' | translate }} *</label>
            </div>
            <div [ngClass]="column">
              <input
                type="text"
                [(ngModel)]="product.beerTechnical.color"
                class="m-input"
                name="color"
                [readonly]="readonly"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
