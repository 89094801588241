<div class="upload-box" [style.maxWidth]="maxWidth" [style.maxHeight]="maxHeight">
  <div *ngIf="!attachment?._id">
    <div *ngIf="previewImage && uploadType == 'image' && attachmentLink">
      <div class="title">Anteprima:</div>
      <img [src]="attachmentLink" />
    </div>

    <label class="fileContainer m-button m-button--medium">
      {{ 'eventWizard.upload' | translate }}
      <input type="file" (change)="handleFile($event)" [attr.accept]="mimeAcceptString" />
    </label>
  </div>

  <div *ngIf="attachment?._id">
    <div *ngIf="previewImage && uploadType == 'image' && attachmentLink">
      <div class="title">{{ 'settingsPage.shop.preview' | translate }}:</div>
      <img [src]="attachmentLink" />
    </div>

    <a class="previewLink" [href]="attachmentLink" target="_blank" *ngIf="uploadType !== 'image'">{{
      attachment.originalName
    }}</a>
    <label class="fileContainer m-button m-button--medium" *ngIf="!readonly">
      {{ 'eventWizard.upload.change' | translate }}
      <input type="file" (change)="handleFile($event)" [attr.accept]="mimeAcceptString" />
    </label>
    <button
    *ngIf="!readonly"
      class="m-button m-button--medium m-button--inline m-button--secondary"
      (click)="deleteFile()"
    >
      {{ 'general.delete' | translate }}
    </button>
  </div>
</div>
