<div class="modal-header">
  <h5 class="modal-title">{{ 'boards.history' | translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div *ngIf="historyItems.length > 0">
    <table class="table table-hover">
      <thead>

        <tr>
            <th scope="col">{{ "boards.history.user" | translate }}</th>
            <th scope="col">{{ "boards.history.commission" | translate }}</th>
            <th scope="col">{{ "boards.history.date" | translate }}</th>
            <th scope="col">{{ "boards.history.evaluation" | translate }}</th>
            <th scope="col">{{ "boards.history.finalEvaluation" | translate }}</th>
            <th scope="col">{{ "boards.history.description" | translate }}</th>
            <th scope="col">{{ "boards.history.faulty" | translate }}</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let board of historyItems">
            <td>
                {{ board.user?.userType == 'board' ? board.user?.commissionDetails?.username :
              board.user?.email }}
            </td>
            <td>{{ board.commission?.commissionDetails?.username }}</td>
            <td>{{ board.date | date:'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ board.evaluation }}</td>
            <td>{{ board.finalEvaluation }}</td>
            <td>{{ board.description }}</td>
            <td>{{ board.faulty ? 'sì' : 'no' }}</td>
        </tr>
      </tbody>

    </table>
</div>


</div>
