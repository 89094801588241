import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AnnouncementLoginDialogData {
  text: string;
  image: string;
}

@Component({
  selector: 'app-announcement-login-dialog',
  templateUrl: './announcement-login-dialog.component.html',
  styleUrls: ['./announcement-login-dialog.component.scss']
})
export class AnnouncementLoginDialogComponent implements OnInit {
  text: string;
  image: string;

  constructor(
    private dialogRef: MatDialogRef<AnnouncementLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnnouncementLoginDialogData
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.text = this.data.text;
      this.image = this.data.image;
    }

    console.log(this.text)
  }

  close() {
    this.dialogRef.close();
  }

}
