import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

export interface SamplingModalData {
  text: string;
  image: string;
  price: number;
  countProducts: number;
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'app-sampling-modal',
  templateUrl: './sampling-modal.component.html',
  styleUrls: ['./sampling-modal.component.scss']
})
export class SamplingModalComponent implements OnInit {
  policy: false;
  dialogData: SamplingModalData = {
    image: null,
    text: '...',
    price: 0.0,
    countProducts: 1,
    startDate: new Date(),
    endDate: new Date(),
  }


  constructor(private dialogRef: MatDialogRef<SamplingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SamplingModalData) {
    this.dialogData = this.data;
  }

  ngOnInit(): void {
  }

  close(accept) {
    this.dialogRef.close(accept);
  }

}
