import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface AnnouncementEntry {
  text: string;
  language: string;
  image: string;
}

@Component({
  selector: 'app-announcement-entry-setup',
  templateUrl: './announcement-entry-setup.component.html',
  styleUrls: ['./announcement-entry-setup.component.scss']
})
export class AnnouncementEntrySetupComponent implements OnInit {
  form = new FormGroup({
    text: new FormControl('', [Validators.required])
  });

  @Input() language: string;
  @Input() text: string;
  @Input() image: any;
  @Output() announcementChanged = new EventEmitter<AnnouncementEntry>();

  languagesAssets = {
    IT: {
      title: 'Italiano',
      icon: 'icons8-italy-50.png'
    },
    EN: {
      title: 'English',
      icon: 'icons8-great-britain-50.png'
    },
    DE: {
      title: 'Deutsch',
      icon: 'icons8-germany-50.png'
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.form.patchValue({ text: this.text });
    this.form.valueChanges.subscribe(v => {
      this.text = v.text;
      this.announcementChanged.emit({
        image: this.image,
        text: this.text,
        language: this.language
      });
    });
  }

  setImage(image) {
    this.image = image;
    this.announcementChanged.emit({ image: this.image, text: this.text, language: this.language });
  }
}
