import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../../core/user.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "../../core/modal.service";
import { EnumService } from "../../core/enum.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @ViewChild('stepGdpr') stepGdpr;
  @ViewChild('stepAccount') stepAccount;
  @ViewChild('stepCanteen') stepCanteen;
  @ViewChild('stepSociety') stepSociety;
  @ViewChild('stepReferent') stepReferent;
  @ViewChild('stepLogo') stepLogo;

  user: any = {
    userType: "supplier"
  }
  electronicInvoiceCodeDisable: boolean = false;
  categories: any[] = [];

  forms = [];
  steps = [];
  stepStrings = [];
  step: number = 0;

  constructor(private userService: UserService,
    private enumService: EnumService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private router: Router) {
  }

  ngOnInit() {
    this.step = 0;
    this.categories = this.enumService.getUserCategories();
    this.initForm();
  }

  initForm() {
    this.steps = [
      this.translateService.instant("gdpr"),
      this.translateService.instant("registration"),
      this.translateService.instant("dati.catalogo"),
      this.translateService.instant("dati.fiscali"),
      this.translateService.instant("dati.referente"),
      this.translateService.instant("dati.immagine")
    ];


  }

  async nextStep() {
    // step privacy gdpr
    if (this.step === 0) {
      ++this.step;
    }
    // step account
    else if (this.step === 1) {
      if (await this.stepAccount.validate()) {
        this.user = this.stepAccount.getUser();
        ++this.step;
      }
    }
    // step dati catalogo
    else if (this.step === 2) {
      if (this.stepCanteen.validate()) {
        this.user = this.stepCanteen.getUser();
        ++this.step;
      }
    }
    // step dati societari
    else if (this.step === 3) {
      if (this.stepSociety.validate()) {
        this.user = this.stepSociety.getUser();
        ++this.step;
      }
    }
    // step dati referente
    else if (this.step === 4) {
      if (this.stepReferent.validate()) {
        this.user = this.stepReferent.getUser();


        ++this.step;

      }
    }
    // step immagine (solo per utenti non di tipo vino)
    else if (this.step === 5) {
      if (this.stepLogo.validate()) {
        this.user = this.stepLogo.getUser();
        this.saveUser()
      }
    }
  }

  prevStep() {
    if (this.step > 0) {
      this.step--
    }
  }

  private saveUser() {
    console.log("Save user!", this.user);
    this.userService.signup(this.user).subscribe(r => {
      console.log("Registration API response:", r);
      this.modalService.success(this.translateService.instant("registration.success"));
      this.router.navigate(["/login"]);
    }, err => {
      console.log("Error registration:", err)
      this.modalService.error(this.translateService.instant("general.form.error"));
    })
  }
}
