import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

declare const swal;

@Component({
  selector: 'app-shop-product-row',
  templateUrl: './shop-product-row.component.html',
  styleUrls: ['./shop-product-row.component.scss']
})
export class ShopProductRowComponent {
  @Input() product;
  @Input() isShop = false;
  @Input() isSettings = false;
  @Input() includeVat = false;

  @Output() updateOrder = new EventEmitter<any>();
  @Output() insertOption = new EventEmitter<void>();
  @Output() modifyOption = new EventEmitter<{ option: any; index: number }>();
  @Output() removeOption = new EventEmitter<{ option: any; index: number }>();
  @Output() modifyProduct = new EventEmitter<any>();

  onSelectionChange() {
    this.updateOrder.emit();
  }

  onSettingsSelectionChange(event: MatSelectChange) {
    if (event.value === 'new') {
      this.insertOption.emit();
    }

    this.product.selectedOption = null;
  }

  editRow(product) {
    this.modifyProduct.emit(product);
  }

  editOption(option, index) {
    this.modifyOption.emit({ option, index });
  }

  onRemoveClick(option, index) {
    swal
      .fire({
        title: 'Sei sicuro?',
        text: `Sei sicuro di voler eliminare l'opzione ${option.quantity}x ${option.basePrice}€`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      })
      .then(result => {
        if (result.value) {
          this.removeOption.emit({ option, index });
        }
      });
  }

  fixPrice(price: number) {
    const fixedPrice = price.toFixed(2);
    return parseFloat(fixedPrice);
  }

  transformToCorrectPrice(price: number, vat: number, maxDigits = 2) {
    const fullPrice = price + (price * vat) / 100;
    const fixedPrice = fullPrice.toFixed(maxDigits);
    return parseFloat(fixedPrice);
  }
}
