<form class="add-product-row-dialog" [formGroup]="form">
  <div class="row">
    <span class="title">{{ isUpdate ? (('settingsPage.shop.edit') | translate ) : (('settingsPage.shop.add') | translate ) }} {{('settingsPage.shop.rowProduct') | translate}}</span>
  </div>

  <div class="row row__image">
    <app-upload-image-box
      (fileUploaded)="setImage($event)"
      (fileDeleted)="setImage(null)"
      [attachment]="image.value"
      [uploadPath]="'/v2/attachments/upload'"
      [uploadType]="'image'"
      [previewImage]="true"
      [title]="'product.image.change' | translate"
      [imageRelativePath]="'/v2/attachments/'">
    </app-upload-image-box>
  </div>

  <div class="row space-between row__input">
    <mat-form-field appearance="outline">
      <mat-label>{{('settingsPage.shop.name') | translate}}</mat-label>
      <input type="text" matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{('settingsPage.shop.description') | translate}}</mat-label>
      <input type="text" matInput formControlName="description" />
    </mat-form-field>
  </div>

  <div class="row space-between row__actions">
    <button class="btn" (click)="close()">{{('settingsPage.shop.cancel') | translate}}</button>
    <button class="btn btn-primary" [disabled]="form.invalid" (click)="addRow()">{{('settingsPage.shop.confirm') | translate}}</button>
  </div>
</form>
