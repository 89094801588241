import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LangNavbarComponent } from './lang-navbar/lang-navbar.component';
import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { TableSortableHeaderComponent } from './table-sortable-header/table-sortable-header.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DotPipe } from './pipes/dot.pipe';
import { NoHtmlPipe } from './pipes/no-html.pipe';
import { NgxEditorModule } from './ngx-editor/ngx-editor.module';
import { NgxEditorModule as ExNgxEditorModule } from "ngx-editor";
import { DatepickerModule } from './datepicker/datepicker.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DaterangepickerModule } from './daterangepicker/daterangepicker.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ProductsSelectorComponent } from './products-selector/products-selector.component';
import { ProductsVisualizerComponent } from './products-visualizer/products-visualizer.component';
import { UserConfirmedEventRegistrationCheckerComponent } from './user-confirmed-event-registration-checker/user-confirmed-event-registration-checker.component';
import { AwardIconComponent } from './award-icon/award-icon.component';
import { TwaSurveyModalComponent } from './twa-survey-modal/twa-survey-modal.component';
import { JoinByPipe } from './pipes/join-by.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormProgressModule } from './form-progress/form-progress.module';
import { AddProductModalModule } from './add-product-modal/add-product-modal.module';
import { UploadImageBoxModule } from './upload-image-box/upload-image-box.module';
import { DirectivesModule } from './directives/directives.module';
import { UsersAutocompleteModule } from './users-autocomplete/users-autocomplete.module';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { TagInputModule } from 'ngx-chips';
import { NgxSelectModule } from 'ngx-select-ex';
import { keymap } from 'prosemirror-keymap';
import { baseKeymap } from 'prosemirror-commands';

import { RegulationModalComponent } from './regulation-modal/regulation-modal.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { EvaluationHistoryModalComponent } from './evaluation-history-modal/evaluation-history-modal.component';
import { RegistrationPaymentInfoModalComponent } from './registration-payment-info-modal/registration-payment-info-modal.component';
import { MaterialModule } from './material/material.module';
import { ShopProductRowComponent } from './components/shop-product-row/shop-product-row.component';
import { AddProductRowDialogComponent } from './dialogs/add-product-row-dialog/add-product-row-dialog.component';
import { AddProductOptionDialogComponent } from './dialogs/add-product-option-dialog/add-product-option-dialog.component';
import { AttachmentPipe } from './pipes/attachment.pipe';
import { PassComponent } from './pass/pass.component';
import { CustomDateRangeModule } from './custom-date-range/custom-date-range.module';
import { AnnouncementLoginDialogComponent } from './dialogs/announcement-login-dialog/announcement-login-dialog.component';
import { AnnouncementEntrySetupComponent } from './components/announcement-entry-setup/announcement-entry-setup.component';
import { SamplingModalComponent } from './sampling-modal/sampling-modal.component';
import { BottomPanelSaveComponent } from './bottom-panel-save/bottom-panel-save.component';

defineLocale('it', itLocale);

@NgModule({
  declarations: [
    LangNavbarComponent,
    HeaderComponent,
    SideMenuComponent,
    CopyrightComponent,
    PrivacyPolicyModalComponent,
    TableSortableHeaderComponent,
    PaginationComponent,
    DotPipe,
    NoHtmlPipe,
    AutocompleteComponent,
    ProductsSelectorComponent,
    ProductsVisualizerComponent,
    UserConfirmedEventRegistrationCheckerComponent,
    AwardIconComponent,
    TwaSurveyModalComponent,
    JoinByPipe,
    RegulationModalComponent,
    FileUploadComponent,
    EvaluationHistoryModalComponent,
    RegistrationPaymentInfoModalComponent,
    ShopProductRowComponent,
    AddProductRowDialogComponent,
    AddProductOptionDialogComponent,
    AttachmentPipe,
    PassComponent,
    AnnouncementEntrySetupComponent,
    AnnouncementLoginDialogComponent,
    SamplingModalComponent,
    BottomPanelSaveComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgxEditorModule,
    DatepickerModule,
    DaterangepickerModule,
    TooltipModule.forRoot(),
    AutocompleteLibModule,
    PerfectScrollbarModule,
    TranslateModule,
    FormProgressModule,
    AddProductModalModule,
    UploadImageBoxModule,
    DirectivesModule,
    UsersAutocompleteModule,
    TagInputModule,
    NgxSelectModule,
    MaterialModule,
    CustomDateRangeModule,
    ExNgxEditorModule.forRoot({
      plugins: [
        keymap(baseKeymap),
      ],
      menu: {
        toolbar: [
          ["bold", "italic"],
          ["underline", "strike"],
          ["code", "blockquote"],
          ["ordered_list", "bullet_list"],
          [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
          ["link"]
        ]
      }
    })
  ],
  exports: [
    ReactiveFormsModule,
    NgxEditorModule,
    LangNavbarComponent,
    HeaderComponent,
    SideMenuComponent,
    CopyrightComponent,
    PrivacyPolicyModalComponent,
    TableSortableHeaderComponent,
    PaginationComponent,
    DotPipe,
    NoHtmlPipe,
    UploadImageBoxModule,
    DatepickerModule,
    DaterangepickerModule,
    TooltipModule,
    AutocompleteLibModule,
    AutocompleteComponent,
    ProductsSelectorComponent,
    ProductsVisualizerComponent,
    DatePipe,
    UserConfirmedEventRegistrationCheckerComponent,
    AwardIconComponent,
    TwaSurveyModalComponent,
    JoinByPipe,
    TranslateModule,
    FormsModule,
    RouterModule,
    FormProgressModule,
    AddProductModalModule,
    DirectivesModule,
    UsersAutocompleteModule,
    NgxSelectModule,
    MaterialModule,
    ShopProductRowComponent,
    AttachmentPipe,
    PassComponent,
    CustomDateRangeModule,
    AnnouncementEntrySetupComponent,
    ExNgxEditorModule,
    BottomPanelSaveComponent
  ],
  entryComponents: [
    PrivacyPolicyModalComponent,
    TwaSurveyModalComponent,
    AddProductRowDialogComponent,
    AddProductOptionDialogComponent,
    AnnouncementLoginDialogComponent,
  ],
  providers: [DatePipe]
})


export class SharedModule { }
