<div>
  <mat-card class="entry">
    <div class="entry__title">
      <span>{{ languagesAssets[language].title }}</span>
      <img
        class="entry__flag"
        src="./assets/img/{{ languagesAssets[language].icon }}"
        alt="{{ languagesAssets[language].title }}"
      />
    </div>
    <form [formGroup]="form">
      <div>
        <span class="entry__subtitle">{{ 'settingsPage.announcement.content' | translate }}</span>
        <ngx-editor formControlName="text"> </ngx-editor>
      </div>

      <div>
        <span class="entry__subtitle">{{ 'dashboard.profile.image.add' | translate }}</span>
        <app-upload-image-box
          (fileUploaded)="setImage($event)"
          (fileDeleted)="setImage(null)"
          [attachment]="image"
          [uploadPath]="'/v2/attachments/upload'"
          [uploadType]="'image'"
          [previewImage]="true"
          [title]="'product.image.change' | translate"
          [imageRelativePath]="'/v2/attachments/'"
        >
        </app-upload-image-box>
      </div>
    </form>
  </mat-card>
</div>
