import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-evaluation-history-modal',
  templateUrl: './evaluation-history-modal.component.html',
  styleUrls: ['./evaluation-history-modal.component.scss']
})
export class EvaluationHistoryModalComponent implements OnInit {

  historyItems = []

  constructor(private modal: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modal.hide();

  }

}
