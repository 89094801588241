import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from 'core/payment.service';
import { take, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {
  localPayment: string;
  eventRegistrationId: string;
  orderId: string;
  callbackUrl: string[];
  subject: string;
  items: string;

  isOrder = false;
  isEventRegistration = false;
  showPaymentInfo = false;
  totalAmount = 0;
  paymentGateway = 'stripe';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.localPayment = this.route.snapshot.queryParams.localPayment;
    this.eventRegistrationId = this.route.snapshot.queryParams.eventRegistration;
    this.orderId = this.route.snapshot.queryParams.order;

    this.isEventRegistration = !!this.eventRegistrationId;
    this.isOrder = !!this.orderId;

    if (this.localPayment) {
      this.paymentService
        .preConfirm(this.localPayment, null)
        .pipe(
          take(1),
          switchMap(_ => {
            return this.paymentService.get(this.localPayment);
          }),
          tap((res: any) => {
            console.log(res);
            if (res) {
              this.paymentGateway = res.paymentGateway;
              if (this.paymentGateway === 'legacy') {
                res.stripeItems.map(item => {
                  this.totalAmount += item.amount / 100;
                });
                this.showPaymentInfo = true;
              }

              switch (res.object) {
                case 'registration': {
                  this.subject = `Iscrizione evento: ${res.eventRegistration.event.name}`;

                  this.callbackUrl = ['dashboard', 'event'];

                  break;
                }

                case 'ticket': {
                  this.subject = `Acquisto biglietti per evento: ${res.eventRegistration.event.name}`;

                  this.callbackUrl = [
                    'dashboard',
                    'event-registration',
                    this.eventRegistrationId,
                    'organization'
                  ];

                  break;
                }

                case 'shop': {
                  this.subject = `Acquisto Shop Portale Merano`;

                  this.callbackUrl = ['dashboard', 'shop', 'detail', this.orderId];
                  break;
                }
                case 'sampling': {
                  this.subject = `Acquisto Campionatura Prodotti`;

                  this.callbackUrl = ['dashboard', 'product', 'new'];
                }
              }

              this.items = res.stripeItems.map(
                item => `${item.quantity}x ${item.name} € ${(item.amount / 100).toFixed(2)}`
              );

              this.totalAmount = res.stripeItems.reduce((total, item) => {
                total += (item.quantity * item.amount) / 100;
                return total;
              }, 0);
            }
          })
        )
        .subscribe();
    }
  }

  goBack() {
    this.router.navigate(this.callbackUrl);
  }
}
