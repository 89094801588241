import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface AddProductRowDialogData {
  name: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-add-product-row-dialog',
  templateUrl: './add-product-row-dialog.component.html',
  styleUrls: ['./add-product-row-dialog.component.scss']
})
export class AddProductRowDialogComponent implements OnInit {
  isUpdate = false;
  form = new FormGroup({
    _id: new FormControl(),
    name: new FormControl('', [Validators.required]),
    description: new FormControl(),
    image: new FormControl()
  });

  get image() {
    if (this.form) {
      return this.form.get('image');
    }
  }

  constructor(
    private dialogRef: MatDialogRef<AddProductRowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddProductRowDialogData
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.isUpdate = !!this.data?.name;
      this.form.patchValue(this.data);
    }
  }

  setImage(image) {
    this.image.setValue(image);
  }

  addRow() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close(null);
  }
}
