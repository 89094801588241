<div class="modal-header">
  <h4>{{ 'payment.info.title' | translate }}</h4>
</div>

<div class="modal-body">
  <div class="row">

    <div class="col-2">
      <h4>{{ 'payment.info.bank_transfer' | translate }}</h4>
    </div>
    <div class="col-1">
      <img src="./assets/img/solid-university.png" alt="bank">
    </div>
  </div>
  <p>Chiediamo gentilmente di proseguire con il bonifico bancario sul conto corrente sotto riportato.
    Una volta da noi confermato l'avvenuto pagamento lo stato cambierá in automatico in "CONFERMATO"</p>
<div class="row">
  <div class="col-2"><p class="title">{{ 'payment.info.accountholder' | translate }}:</p></div>
  <div class="col-10"><p class="value">GOURMET’S INTERNATIONAL SRL</p></div>
</div>
<div class="row">
  <div class="col-2"><p class="title">{{ 'payment.info.bank' | translate }}:</p></div>
  <div class="col-10"><p class="value">DEUTSCHE BANK ITALIA</p></div>
</div>
<div class="row">
  <div class="col-2"><p class="title">IBAN:</p></div>
  <div class="col-10"><p class="value">IT36F0310401620000000871776</p></div>
</div>
<div class="row">
  <div class="col-2"><p class="title">BIC:</p></div>
  <div class="col-10"><p class="value">DEUTITM1474</p></div>
</div>
<div *ngIf="event">
  <div class="row">
    <div class="col-2"><p class="title">CAUSALE:</p></div>
    <div class="col-10"><p class="value">Iscrizione {{ event.name}}</p></div>
  </div>
  <div class="row" *ngIf="amount">
    <div class="col-2"><p class="title">IMPORTO:</p></div>
    <div class="col-10"><p class="value">€ {{ amount | number: '1.2-2'}}</p></div>
  </div>
  </div>
</div>
