<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <div>
    <label class="m-label">{{ 'product.add.image' | translate }}</label>
    <app-upload-image-box
      (fileUploaded)="addProductImage($event)"
      (fileDeleted)="product.image = null"
      [attachment]="product?.image"
      uploadPath="/product/image"
      uploadType="image"
      [previewImage]="true"
      imageRelativePath="/product/image/"
    >
    </app-upload-image-box>
  </div>
</form>
