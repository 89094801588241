import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { PrivacyCheckComponent } from './privacy-check/privacy-check.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { VerifyComponent } from './verify/verify.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProcessComponent } from './dashboard/process/process.component';
import { CancelComponent } from './dashboard/cancel/cancel.component';
import { PagesComponent } from './pages.component';
import { TranslationLoaderResolver } from '../core/translation-loader.resolver';
import { RegisterModule } from './register/register.module';
import { TourVerifyComponent } from './tour-verify/tour-verify.component';
import { TourChangePasswordComponent } from './tour-change-password/tour-change-password.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    resolve: { model: TranslationLoaderResolver }, // abilita l'uso sicuro di translateService.instant()
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'privacy-check',
        component: PrivacyCheckComponent
      },
      {
        path: 'tour-change-password',
        component: TourChangePasswordComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'verify',
        component: VerifyComponent
      },
      {
        path: 'tour-verify',
        component: TourVerifyComponent
      },
      {
        path: 'recovery',
        component: RecoveryComponent
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(module => module.DashboardModule)
      }
    ]
  }
];

@NgModule({
  declarations: [
    PagesComponent,
    LoginComponent,
    PrivacyCheckComponent,
    RecoveryComponent,
    VerifyComponent,
    ChangePasswordComponent,
    ProcessComponent,
    CancelComponent,
    TourVerifyComponent,
    TourChangePasswordComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule.forRoot(routes), FormsModule, RegisterModule]
})
export class PagesModule {}
