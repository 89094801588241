  <button type="button"(click)="dp.toggle()" class="btn btn-primary"><i class="ti ti-time"></i> </button>

  <input class="hidden {{ innerClass }}"
         [ngClass]="{ 'datepicker-invisible': hideInput}"
         #dp="bsDaterangepicker"
         bsDaterangepicker
         (bsValueChange)="changeDate($event)"
         [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', showWeekNumbers: false }"
         [bsValue]="dateRange">


