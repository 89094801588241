import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { CustomDateRangeComponent } from './custom-date-range.component';

defineLocale('it', itLocale);

@NgModule({
  declarations: [CustomDateRangeComponent],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [CustomDateRangeComponent]
})
export class CustomDateRangeModule {
}
