<div class="sampling-modal">
  <div class="sampling-title">
    Per poter continuare devi sottoscrivere l'iscrizione alla campionatura prodotti
  </div>
  <img *ngIf="dialogData.image" class="announcement-image mt-2" src="{{ dialogData.image | attachment }}" alt="" />
  <div class="announcement-content" [innerHTML]="dialogData.text"></div>

  <div [innerHTML]="
      'samplingCampaign.dialog.priceQuestion'
        | translate: { value: dialogData.price, startDate: (dialogData.startDate  | date: 'dd-MM-yyyy'), endDate: (dialogData.endDate | date: 'dd-MM-yyyy'), countProducts: dialogData.countProducts }
    "></div>

  <mat-checkbox class="mt-3" [(ngModel)]="policy">{{ 'samplingCampaign.dialog.policy' | translate }}</mat-checkbox>

  <div class="sampling-buttons">
    <button mat-flat-button color="primary" [disabled]="!policy" (click)="close(true)">{{ 'general.yes' | translate
      }}</button>
    <button mat-flat-button color="primary" (click)="close(false)">{{ 'general.no' | translate }}</button>
  </div>
</div>
