import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-registration-payment-info-modal',
  templateUrl: './registration-payment-info-modal.component.html',
  styleUrls: ['./registration-payment-info-modal.component.scss']
})
export class RegistrationPaymentInfoModalComponent implements OnInit {

  constructor() { }

  @Input() event
  amount;
  ngOnInit(): void {

    if (this.event && this.event.eventRegistration) {
      this.amount = this.event.amount + (this.event.amount * (this.event.vat / 100))

      this.event.eventRegistration.purchasedServices.map(
        item => this.amount +=
          ((item.eventService.price + (item.eventService.price * (item.eventService.vat / 100)) * item.eventService.quantity))
      );
    }

  }

}
