import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AddProductOptionDialogData {
  basePrice: number;
  vat: number;
  quantity: number;
}

@Component({
  selector: 'app-add-product-option-dialog',
  templateUrl: './add-product-option-dialog.component.html',
  styleUrls: ['./add-product-option-dialog.component.scss']
})
export class AddProductOptionDialogComponent implements OnInit {
  isUpdate = false;
  form = new FormGroup({
    basePrice: new FormControl(null, [Validators.required]),
    vat: new FormControl(null, [Validators.required]),
    quantity: new FormControl(null, [Validators.required])
  });

  constructor(
    private dialogRef: MatDialogRef<AddProductOptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddProductOptionDialogData
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.form.patchValue(this.data);
    }
  }

  addOption() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
