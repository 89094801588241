import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../../core/payment.service';
import { take, switchMap, tap } from 'rxjs/operators';
import { ShopService } from 'core/services/shop.service';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  respApi: string;
  paymentId: any;
  callbackUrl: string;
  payment: any;

  // FIXME: For future update: every payment should be done through shop

  /** Shop Payment */
  isOrder = false;

  /** Event Registration Payment */
  isEventRegistration = false;

  /** Ticket Payment */
  isTicket = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private shopService: ShopService
  ) {}

  ngOnInit() {
    this.paymentId = this.route.snapshot.queryParams.localPayment;
    this.isOrder = !!this.route.snapshot.queryParams.order;

    this.paymentService
      .get(this.paymentId)
      .pipe(
        take(1),
        tap((payment: any) => {
          this.payment = payment;

          if (payment.eventRegistration) {
            this.isEventRegistration = true;
          }
        }),
        switchMap((payment: any) => {
          const body = {
            event: this.isEventRegistration ? payment.eventRegistration.event : null,
            order: this.isOrder ? payment.order : null,
            user: payment.user,
            sessionId: payment.stripeSessionId
          };

          return this.paymentService.cancel(body);
        }),
        switchMap(_ => (this.isOrder ? this.shopService.cancelOrder(this.payment.order) : EMPTY)),
        tap(_ => {
          this.respApi = 'Transazione annullata';

          if (this.isOrder) {
            this.callbackUrl = `/dashboard/shop`;
          } else if (this.isEventRegistration) {
            this.callbackUrl = '/dashboard/event';
          } else if (this.isTicket) {
            this.callbackUrl = `/dashboard/event-registration/${this.payment.eventRegistration._id}/tickets`;
          }
        })
      )
      .subscribe();
  }

  undo() {
    this.router.navigate([this.callbackUrl]);
  }
}
