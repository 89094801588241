import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ModalService } from 'core/modal.service';
import { TranslateService } from '@ngx-translate/core';

export enum DigitalFoodProductCategory {
  Aperitivi = 'aperitivi',
  PastoCompleto = 'pasto_completo',
  CibiOrientali = 'cibi_orientali',
  CibiPiccanti = 'cibi_piccanti',
  AntipastiFreddi = 'antipasti_freddi',
  AntipastiCaldi = 'antipasti_caldi',
  AntipastiPesce = 'antipasti_pesce',
  PrimiPasta = 'primi_pasta',
  PrimiRiso = 'primi_riso',
  Zuppe = 'zuppe',
  CarniBianche = 'carni_bianche',
  CarniRosse = 'carni_rosse',
  Bolliti = 'bolliti',
  Arrosti = 'arrosti',
  Brasati = 'brasati',
  Crostacei = 'crostacei',
  PesceForno = 'pesce_forno',
  PesceUmido = 'pesce_umido',
  Fritto = 'fritto',
  FormaggiFreschi = 'formaggi_freschi',
  FormggiStagionati = 'formaggi_stagionati',
  Dolci = 'dolci',
  Dessert = 'dessert'
}

@Component({
  selector: 'app-step7',
  templateUrl: './step7.component.html'
})
export class Step7Component implements OnInit, OnChanges {
  @Input() product: any = {};
  @Input() productDetailMode: boolean;
  @Input() readonly = false;

  tags: string[] = Object.values(DigitalFoodProductCategory);

  @ViewChild('form') form;
  column = 'uk-width-1-1';

  filteredTags: Observable<string[]>;
  tagControl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private modalService: ModalService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.filteredTags = this.tagControl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.tags.slice()))
    );
  }

  ngOnChanges(): void {
    if (this.productDetailMode) {
      this.column = 'uk-width-small-1-2';
    }

    this.product.history = this.product.history || {};
    this.product.description = this.product.description || {};
    this.product.tags = this.product.tags || [];
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim() && this._filter(value).length) {
      this.product.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagControl.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.product.tags.indexOf(tag);

    if (index >= 0) {
      this.product.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.product.tags.push(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.tagControl.setValue(null);
  }

  isValid() {
    return this.form.valid;
  }

  validate() {
    this.form.submitted = true;
    if (!this.isValid()) {
      this.modalService.showFormError();
      return false;
    }
    return true;
  }

  getData() {
    return this.product;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.tags.filter(
      tag =>
        this.translate
          .instant(`digital.category.food.${tag}`)
          .toLowerCase()
          .indexOf(filterValue) === 0
    );
  }
}
