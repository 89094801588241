import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() paginationModel: any = {};
  @Output() paginationModelEmitter: EventEmitter<any> = new EventEmitter();
  @Input() pagesOnly = false;
  @Output() refetch: EventEmitter<any> = new EventEmitter<any>();

  pageLimits: number[] = [5, 10, 25, 50];

  next() {
    if (this.paginationModel.page < this.paginationModel.pages) {
      this.paginationModel.page++;
      this.sendRefetch();
    }
  }

  prev() {
    if (this.paginationModel.page > 1) {
      this.paginationModel.page--;
      this.sendRefetch();
    }
  }

  changeLimit() {
    this.paginationModel.page = 1; // Riporta alla pagina iniziale
    this.sendRefetch();
  }

  pageChanged() {
    if (this.paginationModel.page < 1 || /^[0-9]*$/.test(this.paginationModel.page + '') == false) {
      this.paginationModel.page = 1;
    }
    this.sendRefetch();
  }

  sendRefetch() {
    this.paginationModelEmitter.emit(this.paginationModel);
    this.refetch.emit(this.paginationModel);
  }
}
