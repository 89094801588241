<div class="awardIcon">
  <span *ngIf="!evaluation"></span>
  <div *ngIf="evaluation && evaluation > 0 && year < 2020 && (isAfterAward || showAward)">
    <span *ngIf="evaluation < 88" title="No Award">No Award</span>

    <span *ngIf="evaluation >= 88 && evaluation < 90">
      WineHunter Rosso
    </span>
    <img
      src="/assets/img/award-rosso.png"
      title="Award Rosso"
      *ngIf="evaluation >= 88 && evaluation < 90"
    />

    <span *ngIf="evaluation >= 90 && evaluation < 93">
      WineHunter Gold
    </span>
    <img
      src="/assets/img/award-gold.png"
      title="Award Oro"
      *ngIf="evaluation >= 90 && evaluation < 93"
    />

    <span *ngIf="evaluation >= 93 && evaluation < 95">
      WineHunter Candidato Platinum
    </span>
    <img
      src="/assets/img/award-platinum-candidato.png"
      title="Award Candidato Platino"
      *ngIf="evaluation >= 93 && evaluation < 95"
    />

    <span *ngIf="evaluation >= 95">
      WineHunter Platinum
    </span>
    <img src="/assets/img/award-platinum.png" title="Award Platino" *ngIf="evaluation >= 95" />
  </div>

  <div *ngIf="evaluation && evaluation > 0 && year >= 2020 && (isAfterAward || showAward)">
    <span *ngIf="evaluation < 90" title="No Award">No Award</span>

    <span *ngIf="evaluation >= 90 && evaluation < 93">
      WineHunter Rosso
    </span>
    <img
      src="/assets/img/award-rosso.png"
      title="Award Rosso"
      *ngIf="evaluation >= 90 && evaluation < 93"
    />

    <span *ngIf="evaluation >= 93 && evaluation < 95">
      WineHunter Gold
    </span>
    <img
      src="/assets/img/award-gold.png"
      title="Award Oro"
      *ngIf="evaluation >= 93 && evaluation < 95"
    />

    <span *ngIf="evaluation >= 95 && evaluation < 96">
      WineHunter Candidato Platinum
    </span>
    <img
      src="/assets/img/award-platinum-candidato.png"
      title="Award Candidato Platino"
      *ngIf="evaluation >= 95 && evaluation < 96"
    />

    <span *ngIf="evaluation >= 96">
      WineHunter Platinum
    </span>

    <img src="/assets/img/award-platinum.png" title="Award Platino" *ngIf="evaluation >= 96" />
  </div>

  <div *ngIf="evaluation && evaluation > 0 && !isAfterAward && showText">
    Pubblicato il 16 agosto
  </div>
</div>
