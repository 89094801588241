import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaginatedResponse } from 'models';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) { }

  get(id: string) {
    return this.http.get(`${environment.server}/payment/${id}`);
  }

  getByOrder(orderID: string): Observable<any> {
    return this.http.get(`${environment.server}/payment/order/${orderID}`).pipe(take(1));
  }

  getByEventRegistration(registrationID: string): Observable<any> {
    return this.http
      .get(`${environment.server}/payment/event-registration/${registrationID}`)
      .pipe(take(1));
  }

  search(eventRegistration, object) {
    const params = new HttpParams()
      .set('eventRegistration', eventRegistration)
      .set('object', object);

    return this.http.get(`${environment.server}/payment`, { params });
  }

  add(data: any) {
    return this.http.post(`${environment.server}/payment`, data);
  }

  modify(id: string, data: any) {
    return this.http.put(`${environment.server}/payment/${id}`, data);
  }

  createPayment(eventRegistration, voucherQuantitySelected, transactions, isLegacy: boolean) {
    return this.http.post(`${environment.server}/payment/session/create`, {
      transactions,
      eventRegistration,
      voucherQuantitySelected,
      isLegacy
    });
  }

  createRegistrationPayment(payment) {
    return this.http
      .post(`${environment.server}/payment/event-registration/create`, payment)
      .pipe(take(1));
  }

  preConfirm(id: string, body: any) {
    return this.http.put(`${environment.server}/payment/${id}/preconfirm`, body).pipe(take(1));
  }

  cancel(body) {
    return this.http.post(`${environment.server}/payment/cancel`, body);
  }

  getBillingPortalSession(returnUrl: string) {
    return this.http
      .post(`${environment.serverV2}/billingPortal/session/`, { returnUrl })
      .pipe(take(1));
  }

  checkPaymentMethods() {
    return this.http
      .get(`${environment.server}/payment/gateway/check-payment-methods`)
      .pipe(take(1));
  }

  sendRegistrationPaymentReminder(payment) {
    return this.http.post(`${environment.server}/payment/registration-reminder`, { payment });
  }

  createSamplingPayment(isOffSession: boolean): Observable<any> {
    return this.http
      .post<any>(`${environment.serverV2}/payments`, {
        object: 'sampling',
        isOffSession
      })
      .pipe(take(1));
  }

  getSamplingPayments(page: number, limit: number, body: any): Observable<PaginatedResponse<any>> {
    return this.http
      .post<PaginatedResponse<any>>(
        `${environment.serverV2}/payments/samplings/list?page=${page}&limit=${limit}`,
        body
      )
      .pipe(take(1));
  }

  getSamplingRefunds(page: number, limit: number, body: any): Observable<PaginatedResponse<any>> {
    return this.http
      .post<PaginatedResponse<any>>(
        `${environment.serverV2}/payments/samplings/refunds?page=${page}&limit=${limit}`,
        body
      )
      .pipe(take(1));
  }

  refundSamplingPayment(id: string): Observable<any> {
    return this.http
      .post<any>(`${environment.serverV2}/payments/refunds/${id}`, {
        object: 'sampling'
      })
      .pipe(take(1));
  }

  exportSamplingPayments(body: any) {
    return this.http
      .post(`${environment.serverV2}/payments/samplings/export`, body, {
        responseType: 'arraybuffer'
      })
      .pipe(
        take(1),
        tap(res => {
          let binary = '';
          const bytes = new Uint8Array(res);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset:UTF-8;base64,${window.btoa(
            binary
          )}`;

          const downloadLink = document.createElement('a');
          const fileName = `Payments.xlsx`;

          downloadLink.href = url;
          downloadLink.download = fileName;
          downloadLink.click();
        })
      );
  }
}
