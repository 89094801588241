import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ChangeDetectorRef
} from '@angular/core';
import { FileService } from '../../core/file.service';
import { ModalService } from '../../core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CommonService } from '../../core/common.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-upload-image-box',
  templateUrl: './upload-image-box.component.html',
  styleUrls: ['./upload-image-box.component.scss']
})
export class UploadImageBoxComponent implements OnInit, OnChanges {
  @Input() previewImage = false;
  @Input() uploadType: 'image' | 'general' = 'general';
  @Input() attachment: any;
  @Input() title: string;
  @Input() uploadPath: string;
  @Input() skipResizing = false;
  @Input() maxHeight = '100%';
  @Input() maxWidth = '100%';
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileDeleted: EventEmitter<any> = new EventEmitter<any>();

  mimeAcceptString: string;
  attachmentLink: string;
  @Input() imageRelativePath = '';
  @Input() readonly = false;

  constructor(
    private fileService: FileService,
    private modalService: ModalService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.title = this.title || this.translateService.instant('general.upload');
  }

  ngOnChanges() {
    console.log('ngOnChanges');
    if (this.uploadType === 'image') {
      this.mimeAcceptString = 'image/*';
    } else if (this.uploadType === 'general') {
      this.mimeAcceptString = this.fileService.getSupportedMimes().join(',');
    }

    if (typeof this.attachment === 'string') {
      this.fileService
        .get(this.attachment)
        .pipe(take(1))
        .subscribe(res => (this.attachment = res));
    }

    this.getAttachmentLink();
  }

  handleFile(event: any) {
    const file: File = event ? event.target.files[0] : null;
    const fileName = event ? event.target.files[0].name : 'void_file';

    if (!file) {
      return;
    }

    // TODO verify to move in uploadFile
    if (!this.checkFileSize(file.size)) {
      throw new TypeError('File size too large');
    }

    if (!this.checkMime(file.type)) {
      throw new TypeError('Mime type is not supported');
    }

    if (
      this.uploadType === 'image' &&
      !this.skipResizing &&
      !this.commonService.isInternetExplorer()
    ) {
      console.log('Image resizing...');
      const imageReader = new FileReader();
      const width = 1000;
      const height = 1000;
      imageReader.readAsDataURL(file);
      imageReader.onload = imageEvent => {
        const img = new Image();
        img.src = imageEvent.target.result as string;
        (img.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = img.width;
          elem.height = img.height;

          const ctx = elem.getContext('2d');
          const canvas = ctx.canvas;
          let scale = 1;
          if (img.width > width) {
            // scale = Math.min(canvas.width / img.width, canvas.height / img.height);
            scale = width / img.width;
          }
          canvas.width = canvas.width * scale;
          canvas.height = canvas.height * scale;

          const x = canvas.width / 2 - (img.width / 2) * scale;
          const y = canvas.height / 2 - (img.height / 2) * scale;

          ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

          ctx.canvas.toBlob(
            blob => {
              const newFile = new File([blob], fileName, {
                type: 'image/png',
                lastModified: Date.now()
              });
              if (this.previewImage) {
                const reader: FileReader = new FileReader();
                reader.onload = (eventReader: any) => {
                  this.previewImage = eventReader.target.result;
                };
                reader.readAsDataURL(newFile);
              }
              this.uploadFile(newFile);
            },
            'image/png',
            1
          );
        }),
          (imageReader.onerror = error => console.log(error));
      };
    } else {
      this.uploadFile(file);
    }
  }

  private uploadFile(file: File) {
    console.log('Upload file', file);
    if (!file) {
      return;
    }

    const fileToUpload = file;
    this.fileService.upload(fileToUpload, this.uploadPath).subscribe((r: any) => {
      // console.log("File uploading progress:", r)
      if (r.body) {
        this.attachment = r.body.response;
        console.log('Attachemnt', this.attachment);
        this.getAttachmentLink();
        this.fileUploaded.emit(this.attachment);
      }
    });
  }

  private checkMime(mime: string) {
    const supportedMime = this.fileService.getSupportedMimes();
    return supportedMime.indexOf(mime) > -1;
  }

  private checkFileSize(size: number) {
    // 50MB = 50 * 1024 * 1024
    const maxUploadSize = 52428800;

    return size <= maxUploadSize;
  }

  deleteFile() {
    this.modalService.info(this.translateService.instant('eventWizard.upload.delete')).then(r => {
      if (r.value) {
        // TODO: cancellare i file dal database prima
        this.attachmentLink = null;
        this.fileDeleted.emit(true);
        this.changeDetector.detectChanges();
      }
    });
  }

  private getAttachmentLink() {
    if (this.attachment && (this.attachment._id || typeof this.attachment === 'string')) {
      this.attachmentLink = this.fileService.getUrl(
        typeof this.attachment === 'string' ? this.attachment : this.attachment._id,
        this.imageRelativePath
      );
    }
    this.changeDetector.detectChanges();
  }
}
