<div class="product">
  <div class="product__details-wrapper">
    <div
      class="product__image"
      [ngStyle]="{ background: 'url(' + (product.image | attachment) + ') no-repeat' }"
    ></div>
    <div class="product__details">
      <span class="product__details-name">{{ product.name }}</span>
      <span class="product__details-description">{{ product.description }}</span>
    </div>
  </div>
  <div class="product__select">
    <mat-form-field appearance="outline" class="product__select-field" *ngIf="isShop">
      <mat-label>{{('shop.order.select_quantity') | translate}}</mat-label>
      <mat-select [(ngModel)]="product.selectedOption" (selectionChange)="onSelectionChange()">
        <mat-option [value]="undefined">Seleziona</mat-option>
        <mat-option
          class="product__option"
          *ngFor="let option of product.options"
          [value]="option"
        >
          <div class="option__quantity">{{ option.quantity }}             {{('shop.order.um') | translate}}
          </div>
          <div class="option__base-price">
            {{
              (includeVat ? transformToCorrectPrice(option.basePrice, option.vat, 4) : option.basePrice)
                | currency: 'EUR':'symbol':'.2-2':'it'
            }}
            {{('shop.order.um') | translate}}
          </div>
          <div class="option__price">
            {{
              option.quantity *
                (includeVat ? transformToCorrectPrice(option.basePrice, option.vat, 4) : option.basePrice)
                | currency: 'EUR':'symbol':'.2-2':'it'
            }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="product__select-field" *ngIf="isSettings">
      <mat-label>{{('settingsPage.shop.showOptions') | translate}}</mat-label>
      <mat-select [(ngModel)]="product.selectedOption" (selectionChange)="onSettingsSelectionChange($event)">
        <mat-option [value]="undefined"></mat-option>
        <mat-option
          class="product__option"
          *ngFor="let option of product.options; index as i"
          [value]="option"
        >
          <div class="option__quantity">{{ option.quantity }} {{('shop.order.um') | translate}}</div>
          <div class="option__base-price">
            {{
              (includeVat ? transformToCorrectPrice(option.basePrice, option.vat, 4) : option.basePrice)
                | currency: 'EUR':'symbol':'.2-4':'it'
            }}
            Pz
          </div>
          <div class="option__price">
            {{
              option.quantity *
                (includeVat ? transformToCorrectPrice(option.basePrice, option.vat, 4) : option.basePrice)
                | currency: 'EUR':'symbol':'.2-4':'it'
            }}
          </div>
          <div class="option__action">
            <i class="material-icons" (click)="editOption(option, i)">edit</i>
            <i class="material-icons" (click)="onRemoveClick(option, i)">delete</i>
          </div>
        </mat-option>
        <mat-option value="new">{{('settingsPage.shop.addNewOption') | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="product__total" *ngIf="!isSettings">
    <span class="product__total-price">{{
      (product.selectedOption?.quantity || 0) *
        (includeVat
          ? transformToCorrectPrice(product.selectedOption?.basePrice || 0, product.selectedOption?.vat || 0, 4)
          : product.selectedOption?.basePrice || 0) | currency: 'EUR':'symbol':'.2-2':'it'
    }}</span>
    <span class="product__total-base-price"
      >{{
        (includeVat
          ? transformToCorrectPrice(product.selectedOption?.basePrice || 0, product.selectedOption?.vat || 0, 4)
          : product.selectedOption?.basePrice || 0
        ) | currency: 'EUR':'symbol':'.2-4':'it'
      }}
      Pz</span
    >
  </div>

  <div class="product__action" *ngIf="isSettings">
    <i class="material-icons" (click)="editRow(product)">edit</i>
  </div>
</div>
