<div class="Pagination">
    {{ "pagination.page" | translate }}
    <button mat-icon-button class="Pagination__button" (click)="prev()">
      <mat-icon>navigate_before</mat-icon>
    </button>

    <input
      matInput
      type="number"
           class="Pagination__input"
           [(ngModel)]="paginationModel.page"
           (keyup.enter)="sendRefetch()">

    <button  mat-icon-button class="Pagination__button" (click)="next()">
      <mat-icon>navigate_next</mat-icon>
    </button>

    {{"pagination.of" | translate}} <strong>{{ paginationModel.pages }}</strong> <span [hidden]="pagesOnly"> | {{"pagination.view" | translate}}
    <mat-select
        class="Pagination__select ml-2"
        [(ngModel)]="paginationModel.limit"
        (selectionChange)="changeLimit()">
      <mat-option *ngFor="let limit of pageLimits" [value]="limit">{{ limit }}</mat-option>
  </mat-select>
  {{ "pagination.results.per.page" | translate }} | {{ "pagination.found" | translate }} <strong>{{ paginationModel.total }}</strong> {{ "pagination.total" | translate }}
  </span>
</div>
