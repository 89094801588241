import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-panel-save',
  templateUrl: './bottom-panel-save.component.html',
  styleUrls: ['./bottom-panel-save.component.scss']
})
export class BottomPanelSaveComponent implements OnInit {

  @Output() saveRequest: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }



  onSave() {
    this.saveRequest.emit();
  }
}
