import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private storeService: StoreService
  ) {}

  get(id: string) {
    return this.http.get(`${environment.server}/attachment/${id}`);
  }

  getServerUrl() {
    return environment.server + '/uploads';
  }

  getEventPassesPdfLink(eventId: string, queryString?: string) {
    queryString = queryString || '';
    return `${environment.server}/pdf/event-pass/event/${eventId}` + queryString;
  }

  upload(file: File, uploadRelativeUrl: string, fileName?: any) {
    const formData: FormData = new FormData();
    if (fileName) {
      formData.append('file', file, fileName);
    } else {
      formData.append('file', file, file.name);
    }
    let headers = {};

    if (this.storeService.get('token')) {
      headers = {
        headers: new HttpHeaders({
          Authorization: this.storeService.get('token')
        })
      };

      const req = new HttpRequest(
        'POST',
        environment.server + uploadRelativeUrl,
        formData,
        headers
      );
      return this.http.request(req);
    }

    // const progress = new Subject<number>();
    /*.subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {

                        // calculate the progress percentage
                        const percentDone = Math.round(100 * event.loaded / event.total);

                        // pass the percentage into the progress-stream
                        progress.next(percentDone);
                    } else if (event instanceof HttpResponse) {

                        // Close the progress-stream if we get an answer form the API
                        // The upload is complete
                        progress.complete();
                        this.spinner.hide();
                    }
                });*/
    // return progress.asObservable();
  }

  getUrl(id: string, relativeImagePath: string) {
    return environment.server + relativeImagePath + id;
    // return environment.server + "/event/image/" + id
  }

  getSupportedMimes() {
    return [
      'application/pdf',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'text/plain',
      'application/vnd.oasis.opendocument.text',
      'image/png',
      'image/jpeg',
      'image/svg+xml',
      'image/tiff',
      'image/webp',
      'image/vnd.microsoft.icon',
      'image/bmp',
      'image/gif'
    ];
  }

  downloadFileByUrl(url: string, filename?: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.target = '_blank';
    a.download = filename || 'PDF.pdf';
    a.click();
    a.remove();
  }
}
