<form class="add-product-option-dialog" [formGroup]="form">
  <div class="row">
    <span class="title">{{ isUpdate ? 'Modifica' : 'Aggiungi una nuova' }} opzione di acquisto</span>
  </div>

  <div class="row space-between row__input">
    <mat-form-field appearance="outline">
      <mat-label>{{('shop.order.unit_price') | translate}}</mat-label>
      <input type="number" matInput formControlName="basePrice" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{('shop.order.tax') | translate}}</mat-label>
      <input type="number" matInput formControlName="vat" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{('shop.order.quantity') | translate}}</mat-label>
      <input type="number" matInput formControlName="quantity" />
    </mat-form-field>
  </div>

  <div class="row space-between row__actions">
    <button class="btn" (click)="close()">{{('settingsPage.shop.cancel') | translate}}</button>
    <button class="btn btn-primary" (click)="addOption()" [disabled]="form.invalid">{{('settingsPage.shop.confirm') | translate}}</button>
  </div>
</form>
