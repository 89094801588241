<div class="Card__title" *ngIf="productDetailMode">
  {{('digital.mwfData') | translate }}
</div>

<form #form="ngForm" [ngClass]="{ submitted: form.submitted }" novalidate>
  <!--vino-->
  <div [ngClass]="{ highlight: !productDetailMode }">
    <div class="uk-grid">
      <div [ngClass]="column">
        <label>{{('digital.productDescriptionIT') | translate }}</label>
      </div>
      <div [ngClass]="column">
        <textarea
          name="description__it"
          type="text"
          [(ngModel)]="product.description.it"
          [readonly]="readonly"
          maxlength="750"
        ></textarea>
      </div>
    </div>

    <div class="uk-grid">
      <div [ngClass]="column">
        <label>{{('digital.productDescriptionEN') | translate }}</label>
      </div>
      <div [ngClass]="column">
        <textarea
          name="description__en"
          type="text"
          [(ngModel)]="product.description.en"
          [readonly]="readonly"
          maxlength="750"
        ></textarea>
      </div>
    </div>

    <div class="uk-grid">
      <div [ngClass]="column">
        <label>{{('digital.productDescriptionDE') | translate }}</label>
      </div>
      <div [ngClass]="column">
        <textarea
          name="description__de"
          type="text"
          [(ngModel)]="product.description.de"
          [readonly]="readonly"
          maxlength="750"
        ></textarea>
      </div>
    </div>

    <div class="uk-grid">
      <div [ngClass]="column">
        <label>{{('digital.storyProductIT') | translate }}</label>
      </div>
      <div [ngClass]="column">
        <textarea
          name="history__it"
          type="text"
          [(ngModel)]="product.history.it"
          [readonly]="readonly"
          maxlength="750"
        ></textarea>
      </div>
    </div>

    <div class="uk-grid">
      <div [ngClass]="column">
        <label>{{('digital.storyProductEN') | translate }}</label>
      </div>
      <div [ngClass]="column">
        <textarea
          name="history__en"
          type="text"
          [(ngModel)]="product.history.en"
          [readonly]="readonly"
          maxlength="750"
        ></textarea>
      </div>
    </div>

    <div class="uk-grid">
      <div [ngClass]="column">
        <label>{{('digital.storyProductDE') | translate }}</label>
      </div>
      <div [ngClass]="column">
        <textarea
          name="history__de"
          type="text"
          [(ngModel)]="product.history.de"
          [readonly]="readonly"
          maxlength="750"
        ></textarea>
      </div>
    </div>

    <div class="uk-grid">
      <mat-form-field [ngClass]="column">
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip
            *ngFor="let tag of product.tags"
            [selectable]="true"
            [removable]="true"
            [disabled]="readonly"
            (removed)="removeTag(tag)"
          >
            {{ 'digital.category.food.' + tag | translate }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="{{('digital.productAssociation') | translate }}"
            #tagInput
            [formControl]="tagControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
            {{ 'digital.category.food.' + tag | translate }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>
