import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pass',
  templateUrl: './pass.component.html',
  styleUrls: ['./pass.component.scss']
})
export class PassComponent implements OnInit {

  @Input() pass;
  @Input() editable: boolean;
  @Output() download = new EventEmitter<string>();
  @Output() datesUpdate = new EventEmitter<Date[]>();


  startDate: Date;
  endDate: Date;
  constructor() { }

  ngOnInit(): void {
    this.startDate = new Date(this.pass.startDate);
    this.endDate = new Date(this.pass.endDate);
  }


  downloadPass(passId: string) {
    this.download.emit(passId);
  }

  updateDates(event: any) {
    this.pass.startDate = event[0];
    this.pass.endDate = event[1];
    this.datesUpdate.emit(event);
  }
}
